
import { Component, Mixins, Vue } from 'vue-property-decorator'
import FormContainer from "@/components/processing_steps/commons/forms/FormContainer.vue"
import CharField from "@/components/processing_steps/commons/fields/CharField.vue"
import axios from "axios"
import { API_URLS, isZO } from "@/utils/helpers"
import ToastMixin from "@/mixins/ToastMixin"
import UserMixin from "@/mixins/UserMixin"
import { EnabledFeatures } from "@/types/features"

@Component({
  components: { CharField, FormContainer }
})
export default class LoginPage extends Mixins(ToastMixin, UserMixin, Vue) {
  username = ''
  password = ''
  remember_me = false
  signUpUrl = ''
  formData = { identification: '', password: '', remember_me: false }
  enabled_features: EnabledFeatures
  passwortResetUrl = API_URLS.ACCOUNT.PASSWORD.RESET

  async submitForm (event: Event): Promise<void> {
    event.preventDefault()
    await axios.post(API_URLS.V3.LOGIN, this.formData).then(response => {
      this.$emit('success')
      this.fetchUserProfile(true)
      this.makeToast(
        'success',
        this.$gettext('Logged in'),
        ''
      )
      if (response.data.success_url) {
        window.location.href = window.location.origin + response.data.success_url
      }
    }).catch(error => {
      this.makeToast(
        'danger',
        this.$gettext('Error'),
        error.response.data.non_field_errors
      )
    })
  }

  getSignUpUrl () {
    this.signUpUrl = isZO()
      ? API_URLS.SIGNUP.ORGANIZATION
      : this.enabled_features.enable_user_companies || this.enabled_features.enable_user_projects
        ? API_URLS.SIGNUP.CHOOSE
        : API_URLS.SIGNUP.SIGNUP
  }

  async checkFeature (features: string) {
    return axios.get(
      API_URLS.PLATFORMFEATURES.CHECK_FEATURE_ANONYMOUS(features)
    ).then(response => {
      return response.data
    })
  }

  async created (): Promise<void> {
    this.enabled_features = await this.checkFeature('enable_user_companies,enable_user_projects')
    this.getSignUpUrl()
  }
}
