import VueRouter from 'vue-router'
import VueWait from 'vue-wait'

import App from '@/views/LoginPage.vue'
import store from '@/store'

import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/styles/index.scss'
import { setUpVue } from '@/utils/setup'

const Vue = setUpVue()

Vue.use(VueRouter)

new Vue({
  store,
  wait: new VueWait({ useVuex: true }),
  render: h => h(App)
}).$mount('#app')
